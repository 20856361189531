import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import shortid from "shortid"

import SidebarMenu from "../components/SidebarMenu"
import SEO from "../components/SEO.js"

const AboutCategory = ({
  data: { aboutCategory, aboutCategories },
  pageContext: { locale, breadcrumbs, slug },
  location,
}) => {
  const meta = {
    title: aboutCategory.data.meta_title,
    description: aboutCategory.data.meta_description,
    keywords: aboutCategory.data.meta_keywords,
    author: "Emil Mureșan",
  }

  const schema = {
    "@graph": [],
  }
  const ogp = []

  ogp.push({
    property: "og:image",
    content: aboutCategory.data.parent_page.document.data.cover.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: aboutCategory.data.parent_page.document.data.cover.alt,
  })
  ogp.push({
    property: "og:image:width",
    content:
      aboutCategory.data.parent_page.document.data.cover.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content:
      aboutCategory.data.parent_page.document.data.cover.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const categories = aboutCategories.edges.map(r => r.node)

  return (
    <>
      <SEO
        schema={JSON.stringify(schema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
      />
      <div id="about">
        <Img
          className="no-border full-image"
          alt={aboutCategory.data.parent_page.document.data.cover.alt}
          fluid={aboutCategory.data.parent_page.document.data.cover.fluid}
        />

        <div className="content">
          <h1>{aboutCategory.data.title.text.toUpperCase()}</h1>

          <div className="list">
            {aboutCategory.data.content.map(
              ({ section: { document: item, id } }) => (
                <article key={shortid.generate()}>
                  <div className="article-head">
                    <h2>
                      {item.data.title.text}
                      <span className="subtitle">
                        &nbsp;| {item.data.subtitle.text}
                      </span>
                    </h2>
                    <hr className="fancy" />
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.data.description.html,
                    }}
                  />
                </article>
              )
            )}
          </div>
        </div>
      </div>

      <SidebarMenu
        items={categories}
        current={slug}
        breadcrumbs={breadcrumbs}
      />
    </>
  )
}

export default AboutCategory

export const query = graphql`
  query AboutCategoryQuery($slug: String!, $locale: String!) {
    aboutCategory: prismicAboutCategory(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      data {
        meta_title
        meta_description
        meta_keywords
        title {
          text
        }
        parent_page {
          document {
            ... on PrismicAboutPage {
              data {
                cover {
                  alt
                  dimensions {
                    height
                    width
                  }
                  fluid {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
            }
          }
        }

        content {
          section {
            id
            document {
              ... on PrismicAboutSection {
                id
                data {
                  title {
                    text
                  }
                  subtitle {
                    text
                  }
                  description {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }

    aboutCategories: allPrismicAboutCategory(
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            parent_page {
              document {
                ... on PrismicAboutPage {
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          uid
          lang
        }
      }
    }
  }
`

AboutCategory.propTypes = {
  data: PropTypes.shape({
    aboutCategory: PropTypes.object.isRequired,
    aboutCategories: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
